import React from "react"
import { graphql } from "gatsby"

import BlurbShape from "../../components/common/BlurbShape"
import MasterForm from "../../components/forms/master-form/Form"
import Section from "wildComponents/Section"
import { Heading, Stack, VStack } from "@chakra-ui/react"

export const query = graphql`
  fragment LP_FORM_DATA on WpPage {
    acf_form {
      blurbTitle
      blurb1 {
        textLeft
        textRight
        image
      }
      blurb2 {
        textRight
        textLeft
        image
      }
      blurb3 {
        textRight
        textLeft
        image
      }
    }
  }
`

function LPForm({ data }) {
  return (
    <Section
      bg="dark.default"
      containerStyles={{ position: "relative" }}
      zIndex="2"
    >
      <Stack
        spacing={[12, 20, 40]}
        direction={["column-reverse", "column-reverse", "row"]}
        transform={["translateY(-10%)", "translateY(-10%)", "unset"]}
      >
        <VStack align="flex-start" flex="1">
          <VStack spacing={[8, 8, 20]} w="full" align="flex-start">
            <Heading color="brandBlue.default">{data.blurbTitle}</Heading>
            <BlurbShape data={data.blurb1} />
            <BlurbShape data={data.blurb2} />
            <BlurbShape data={data.blurb3} />
          </VStack>
        </VStack>
        <VStack
          align="flex-start"
          flex="1"
          bg="brandConcrete.default"
          px={8}
          py={8}
          spacing={8}
          data-speed="0.8"
          transform={["unset", "unset", "translateY(-25%)"]}
          id="contact"
          rounded="lg"
        >
          <Heading as="h3">Free Consultation</Heading>
          <MasterForm w="full" />
        </VStack>
      </Stack>
    </Section>
  )
}

export default LPForm
