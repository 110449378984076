import React from "react"

import DIcon from "../../assets/icons/DIcon"
import QIcon from "../../assets/icons/QIcon"
import StarIcon from "../../assets/icons/StarIcon"
import CloudIcon from "../../assets/icons/CloudIcon"
import AnvilIcon from "../../assets/icons/AnvilIcon"
import CircleIcon from "../../assets/icons/CircleIcon"
import CrossIcon from "../../assets/icons/CrossIcon"
import { Box, Heading, Stack } from "@chakra-ui/react"

function BlurbShape({ data }) {
  const iconProps = {
    positon: "absolute",
    left: "0",
    bottom: "0",
    transform: ["translateY(10px)", "translateY(10px)", "translateY(30px)"],
    h: "120%",
    w: "120%",
    zIndex: "-1",
    color: "#e0db2a",
  }

  return (
    <Stack
      direction={["column", "column", "row"]}
      justify="flex-start"
      w="full"
      spacing={[8, 12, 12]}
    >
      <Box
        width={["40px", "40px", "100px"]}
        h={["40px", "40px", "100px"]}
        position="relative"
      >
        <Heading
          className="jumbo light"
          sx={{ "&.jumbo.light": { fontWeight: "light" } }}
          position="absolute"
          top="0"
          width="100px"
          zIndex="1"
          color="white"
        >
          {data.textLeft}
        </Heading>
        {data.image === "D" && <DIcon {...iconProps} />}
        {data.image === "Q" && <QIcon {...iconProps} />}
        {data.image === "Star" && <StarIcon {...iconProps} />}
        {data.image === "Cloud" && <CloudIcon {...iconProps} />}
        {data.image === "Anvil" && <AnvilIcon {...iconProps} />}
        {data.image === "Circle" && <CircleIcon {...iconProps} />}
        {data.image === "Cross" && <CrossIcon {...iconProps} />}
      </Box>
      <Box
        flexGrow="1"
        h="full"
        transform={[
          "unset",
          "unset",
          "translateX(10px)",
          "translateX(10px)",
          "translateX(23px)",
        ]}
      >
        <Heading
          as="h4"
          className="thin"
          color="white"
          dangerouslySetInnerHTML={{ __html: data.textRight }}
        />
      </Box>
    </Stack>
  )
}

export default BlurbShape
